export default {
  computed: {
    rules() {
      return {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('global.pleaseEnterAValidEmail')
        },
        required: value => (value != null && value !== '') || this.$t('global.pleaseFillOutThisField'),
        true: value => value === true || this.$t('global.pleaseAcceptThisField'),
      }
    }
  }
}