<template>
  <div>
    <p class="text-h5 mt-6">Reinigungsadresse</p>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Firma'"
                        v-model="configuration.company2" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Straße'"
                        v-model="configuration.street2"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Nr.'"
                        v-model="configuration.streetNumber2"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'PLZ'"
                        v-model="configuration.postalCode2"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Stadt'"
                        v-model="configuration.city2"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field disabled
                        filled
                        hide-details="auto"
                        v-bind:label="'Land'"
                        v-model="configuration.land2" />
        </v-col>
      </v-row>
  </div>
</template>

<script>
  import rules from '@/mixins/rules'
  
  export default {
    mixins: [ rules ],
    name: 'ExtraAdress',
    props: {
      configuration: Object
    }
  }
</script>