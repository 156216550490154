<template>
  <div>
    <v-row>
      <v-col cols="12"
            md="4"
            v-for="item in items" 
            v-bind:key="item.label" >

        <v-checkbox class="mt-0"  hide-details="always" v-bind:label="item.text"
                    v-bind:value="value[item.value]"
                    v-on:change="toggleServices({ key: item.value, value: $event })" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    methods: {
      toggleServices({ key, value }) {
        if (value) {
          this.value.push(key)
        } else {
          const index = this.value.indexOf(key)

          if (index > -1) {
            this.value.splice(index, 1)
          }
        }

      }
    },
    name: 'CheckboxSelect',
    props: {
      items: Array,
      value: Array
    }
  }
</script>