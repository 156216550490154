<template>
  <v-app>
   
    <!--<v-app-bar app
               clipped-left
               color="primary"
               dark
               flat>

      <v-toolbar-title>
        Nimanaj Dienstleistungen
      </v-toolbar-title>

      <v-spacer />
    </v-app-bar>-->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      miniVariant: null,
      navigationDrawer: null
    }),
    name: 'App',
    methods:{
      updateUi() {
        this.miniVariant = this.$vuetify.breakpoint.lgAndUp
        this.navigationDrawer = this.$vuetify.breakpoint.lgAndUp
      }
    },
    mounted() {
      this.updateUi()
    },
    watch: {
      '$vuetify.breakpoint.lgAndUp'() {
        this.updateUi()
      }
    }
  }
</script>