<template>
  <div>
    <v-form ref="form">
      <v-card-text>
        <p class="text-h5">Wunschtermin</p>
        <v-row>
          <v-col cols="12" md="6">
            <date-field filled
                        hide-details="auto"
                        v-bind:label="'Wunschtermin 1'"
                        v-model="configuration.date"
                        v-bind:rules="[ rules.required ]" />
          </v-col>
          <v-col cols="12" md="6">
            <date-field filled
                        hide-details="auto"
                        v-bind:label="'Wunschtermin 2'"
                        v-model="configuration.date2" />
          </v-col>

        </v-row>
        <p class="text-h5 mt-6">Objektdetails</p>
        <v-row>
          <v-col cols="12"
                md="4">
            <v-select filled
                      hide-details="auto"
                      v-bind:label="'Objektart'"
                      v-bind:items="descriptor.type.options"
                      v-model="configuration.type"
                      v-bind:rules="[ rules.required ]" />
          </v-col>
          <v-col cols="12"
                md="4">
            <v-text-field filled
                          hide-details="auto"
                          v-bind:label="'Stockwerk'"
                          v-bind:rules="[ rules.required ]"
                          v-model="configuration.level" />
          </v-col>
          <v-col cols="12"
                md="4">
            <v-select filled
                      hide-details="auto"
                      v-bind:label="'Art der Möbelierung'"
                      v-bind:items="descriptor.furniture.options"
                      v-model="configuration.furniture"
                      v-bind:rules="[ rules.required ]" />
          </v-col>
        </v-row>
        <p class="text-h5 mt-6">Details zur Reinigung</p>
        <v-row>
          <v-col cols="12" md="4">
            <v-select filled
                      hide-details="auto"
                      v-bind:label="'Anlass der Reinigung'"
                      v-bind:items="descriptor.cleaningTypeWindow.options"
                      v-model="configuration.cleaningTypeWindow"
                      v-bind:rules="[ rules.required ]" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field filled
                          hide-details="auto"
                          v-bind:label="'Fensterflügel'"
                          type="number"
                          v-model="configuration.windowNumber"
                          v-bind:rules="[ rules.required ]" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field filled
                          hide-details="auto"
                          type="number"
                          v-bind:label="'max. Raumhöhe'"
                          suffix="m"
                          v-model="configuration.maxRoom"
                          v-bind:rules="[ rules.required ]" />
          </v-col>
          <v-col cols="12">
            <icon-select-field v-bind:label="'Grad der Verschmutzung'"
                              v-bind:items="descriptor.dirtyness.options"
                              v-model="configuration.dirtyness" />
          </v-col>
        </v-row>
        <p class="text-h5 mt-6">Reinigungswünsche</p>
        <checkbox-select v-bind:items="descriptor.windowServices.options" v-bind:value="configuration.windowServices"  />
        <p class="text-h5 mt-6">Zusätzliche Informationen</p>
        <v-row>
          <v-col>
            <v-textarea filled
                        hide-details="auto"
                        v-bind:label="'Beschreiben Sie Ihre geplante Reinigung'"
                        v-model="configuration.comment" />
          </v-col>
        </v-row>
        <invoice-address v-bind:configuration="configuration" />
        <v-switch class="mt-6" inset hide-details="always"
                  v-bind:label="'Seperate Reinigungsadresse hinzufügen'"
                  v-model="configuration.extraAdress">
        </v-switch>
        <div v-if="configuration.extraAdress">
          <extra-address v-bind:configuration="configuration" />
        </div>

        <v-checkbox class="mt-12"  hide-details="auto" v-bind:label="'Ich erkläre mich damit einverstnden, dass meine Daten zur Bearbeitung meines Anliegens verwendet werden und stimme hiermit den AGB und Datenschutzerklärung zu.'"
                    v-model="configuration.acceptsDataPrivacy"
                    v-bind:rules="[ rules.true ]" />

          <v-alert type="error" class="mt-6" v-bind:value="error">Bitte füllen Sie alle Felder aus.</v-alert>

          <v-btn class="mt-6"
                depressed
                block
                color="primary"
                v-on:click="send">
            <v-icon left>
              mdi-check
            </v-icon>
            Angebot unverbindlich anfordern
          </v-btn>

      </v-card-text>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import rules from '@/mixins/rules'

  import DateField from '@/components/DateField.vue'
  import IconSelectField from '@/components/IconSelectField.vue'
  import CheckboxSelect from '@/components/CheckboxSelect.vue'
  import InvoiceAddress from '@/components/InvoiceAddress.vue'
  import CheckboxDsgvo from '@/components/CheckboxDsgvo.vue'
  import ExtraAddress from '@/components/ExtraAddress.vue'

  export default {
    components: {
      DateField,
      IconSelectField,
      CheckboxSelect,
      InvoiceAddress,
      CheckboxDsgvo,
      ExtraAddress
    },
    data: () => ({
      error: false
    }),
    mixins: [ rules ],
    computed: {
      ...mapGetters({
        descriptor: 'base/descriptor'
      })
    },
    methods: {
      send() {
        this.error = false
        if (!this.$refs.form.validate()) {
          this.error = true
          return
        }

        this.$emit('send', 'Fensterreinigung')
      }
    },
    name: 'WindowCleanConfigurationDetails',
    props: {
      configuration: Object
    }
  }
</script>