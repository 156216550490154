<template>
  <div>
    <p class="text-caption">{{ label }}</p>
    <v-item-group v-bind:value="value"
                  v-on:change="$emit('input', $event)">
      <v-row>
        <v-col cols="auto"
               class="text-center"
               v-bind:key="item.value"
               v-for="item in items">
          <v-item v-bind:value="item.value"
                  v-slot="{ active, toggle }">
            <v-card flat
                    v-bind:color="active ? 'primary' : 'grey lighten-3'"
                    v-on:click="toggle">
              <v-card-text class="py-1 px-4" v-bind:class="active ? 'white--text' : 'grey--text text--darken-3'">
                <v-avatar>
                  <v-icon v-bind:class="active ? 'white--text' : 'grey--text text--darken-3'">
                          {{ item.icon }}
                  </v-icon>
                </v-avatar>
                <p>{{ item.text }}</p>
              </v-card-text>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </div>
</template>

<script>

  export default {
    name: 'IconSelectField',
    props: {
      label: String,
      items: Array,
      value: String
    }
  }
</script>