<template>
  <div>
    <v-card flat>
      <v-tabs v-bind:vertical="$vuetify.breakpoint.mdAndUp"
      v-bind:show-arrows="!$vuetify.breakpoint.mdAndUp"
              v-bind:value="tab"
              v-on:change="$emit('update:tab', $event)"
              background-color="primary" dark>
        <v-tab class="left-text mb-2">
          <v-icon left>
            mdi-home
          </v-icon>
          Normale Reinigung
        </v-tab>
        <v-tab class="mb-2">
          <v-icon left>
            mdi-curtains
          </v-icon>
          Fensterreinigung
        </v-tab>
        <v-tab class="mb-2">
          <v-icon left>
            mdi-rug
          </v-icon>
          Teppichreinigung
        </v-tab>
        <v-tab class="mb-2">
          <v-icon left>
            mdi-sofa
          </v-icon>
          Polsterreinigung
        </v-tab>
        <v-tab class="mb-2">
          <v-icon left>
            mdi-vacuum
          </v-icon>
          Frühjahrsputz
        </v-tab>
        <v-tab class="mb-2">
          <v-icon left>
            mdi-home-switch
          </v-icon>
          Endreinigung
        </v-tab>
        <v-tab class="mb-2">
          <v-icon left>
            mdi-home-flood
          </v-icon>
          Messi Wohnung
        </v-tab>

        <!-- Normale Reinigung -->
        <v-tab-item class="configuration-item">
          <regular-clean-configuration-details v-bind:configuration="configuration"
                                               v-on:send="$emit('send', $event)" />

        </v-tab-item>
        <!-- Fensterreinigung -->
        <v-tab-item class="configuration-item">
          <window-clean-configuration-details v-bind:configuration="configuration"
          v-on:send="$emit('send', $event)" />
        </v-tab-item>
        <!-- Teppichreinigung -->
        <v-tab-item class="configuration-item">
          <carpet-clean-configuration-details v-bind:configuration="configuration"
          v-on:send="$emit('send', $event)" />
        </v-tab-item>
        <!-- Polsterreinigung -->
        <v-tab-item class="configuration-item">
          <cushion-clean-configuration-details v-bind:configuration="configuration"
          v-on:send="$emit('send', $event)" />
        </v-tab-item>
        <!-- Frühjarputz -->
        <v-tab-item class="configuration-item">
          <spring-clean-configuration-details v-bind:configuration="configuration"
          v-on:send="$emit('send', $event)" />
        </v-tab-item>
        <!-- Endreinigung -->
        <v-tab-item class="configuration-item">
          <final-clean-configuration-details v-bind:configuration="configuration"
          v-on:send="$emit('send', $event)" />
        </v-tab-item>
        <!-- Messi Wohung -->
        <v-tab-item class="configuration-item">
          <messi-clean-configuration-details v-bind:configuration="configuration"
          v-on:send="$emit('send', $event)" />
        </v-tab-item>
        
      </v-tabs>

     
    </v-card>
  </div>
</template>

<script>
  import RegularCleanConfigurationDetails from '@/components/RegularCleanConfigurationDetails.vue'
  import WindowCleanConfigurationDetails from '@/components/WindowCleanConfigurationDetails.vue'
  import CarpetCleanConfigurationDetails from '@/components/CarpetCleanConfigurationDetails.vue'
  import CushionCleanConfigurationDetails from '@/components/CushionCleanConfigurationDetails.vue'
  import SpringCleanConfigurationDetails from '@/components/SpringCleanConfigurationDetails.vue'
  import FinalCleanConfigurationDetails from '@/components/FinalCleanConfigurationDetails.vue'
  import MessiCleanConfigurationDetails from '@/components/MessiCleanConfigurationDetails.vue'

  export default {
    components: {
      RegularCleanConfigurationDetails,
      WindowCleanConfigurationDetails,
      CarpetCleanConfigurationDetails,
      CushionCleanConfigurationDetails,
      SpringCleanConfigurationDetails,
      FinalCleanConfigurationDetails,
      MessiCleanConfigurationDetails
    },
    name: 'ServiceConfigurationCard',
    props: {
      configuration: Object,
      tab: Number
    }
  }
</script>

<style scoped>
  .configuration-item {
    max-height: 640px;
    overflow-y: scroll;
  }

  .v-tab {
    justify-content: inherit;
  }
</style>