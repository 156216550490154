<template>
  <v-row>
    <v-radio-group>
      <v-row>
        <v-col cols="12"
               md="4"
               v-for="item in items" 
               v-bind:key="item.label">
          <v-radio v-bind:label="item.label"
                   v-bind:value="item.label" />
        </v-col>
      </v-row>
    </v-radio-group>
  </v-row>
</template>

<script>
  export default {
    name: 'RadioSelect',
    props: {
      items: Array
    }
  }
</script>