import Vue from 'vue'
import VueRouter from 'vue-router'

import baseRoutes from './base-routes'

Vue.use(VueRouter)

const routes = [
  ...baseRoutes
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  //mode: 'history',
  routes
})

export default router