<template>
  <v-container>
    <v-alert type="success">
      Willkommen auf der Services-Seite!
    </v-alert>

      <span>Registration successful!</span>
      <v-icon dark>
        mdi-checkbox-marked-circle
      </v-icon>
  </v-container>
</template>

<script>
  export default {
    name: 'Services'
  }
</script>