<template>
  <div>
    <v-form ref="form">
      <v-card-text>
        <p class="text-h5">Wunschtermin</p>
        <v-row>
          <v-col cols="12" md="6">
            <date-field filled
                        hide-details="auto"
                        v-bind:label="'Wunschtermin 1'"
                        v-model="configuration.date"
                        v-bind:rules="[ rules.required ]" />
          </v-col>
          <v-col cols="12" md="6">
            <date-field filled
                        hide-details="auto"
                        v-bind:label="'Wunschtermin 2'"
                        v-model="configuration.date2" />
          </v-col>

        </v-row>
        <p class="text-h5 mt-6">Objektdetails</p>
        <v-row>
          <v-col cols="12"
                md="4">
            <v-select filled
                      v-bind:rules="[ rules.required ]"
                      hide-details="auto"
                      v-bind:label="'Objektart'"
                      v-bind:items="descriptor.type.options"
                      v-model="configuration.type" />
          </v-col>
          <v-col cols="12"
                md="4">
            <v-text-field filled
            v-bind:rules="[ rules.required ]"
                          hide-details="auto"
                          v-bind:label="'Stockwerk'"
                          v-model="configuration.level" />
          </v-col>
          <v-col cols="12"
                md="4">
            <v-select filled
                      hide-details="auto"
                      v-bind:rules="[ rules.required ]"
                      v-bind:label="'Art der Möbelierung'"
                      v-bind:items="descriptor.furniture.options"
                      v-model="configuration.furniture" />
          </v-col>
        </v-row>
        <p class="mt-6 text-h5">Details zur Reinigung</p>
        <v-row>
          <v-col cols="12" md="4">
            <stepper v-bind:label="'Loser Teppich'"
                v-model="configuration.carpetNumber"
                 />
          
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field filled
                          hide-details="auto"
                          v-bind:label="'Gesamtfläche'"
                          suffix="qm"
                          type="number"
                          v-bind:rules="[ rules.required ]"
                          v-model="configuration.totalArea" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field filled
                          hide-details="auto"
                          v-bind:label="'Festverlegter Teppich'"
                          suffix="qm"
                          type="number"
                          v-bind:rules="[ rules.required ]"
                          v-model="configuration.fixedCarpet" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <icon-select-field v-bind:label="'Grad der Verschmutzung'"
                              v-bind:items="descriptor.dirtyness.options"
                              v-model="configuration.dirtyness" />
          </v-col>
        </v-row>
        <p class="text-h5 mt-6">Reinigungswünsche</p>
        <checkbox-select v-bind:items="descriptor.carpetServices.options" v-bind:value="configuration.carpetServices"  />
        <p class="text-h5 mt-6">Zusätzliche Informationen</p>
        <v-row>
          <v-col>
            <v-textarea filled
                        hide-details="auto"
                        v-bind:label="'Beschreiben Sie Ihre geplante Reinigung'"
                        v-model="configuration.comment"/>
          </v-col>
        </v-row>
        <invoice-address v-bind:configuration="configuration" />
        <!-- Checkbox: Separate Reinigungsadresse hinzufügen -->
        <v-switch class="mt-6" inset
                  hide-details="always"
                  v-bind:label="'Seperate Reinigungsadresse hinzufügen'"
                  v-model="configuration.extraAdress">
        </v-switch>
        <div v-if="configuration.extraAdress">
          <extra-address v-bind:configuration="configuration" />
        </div>
        <!-- Checkbox: Separater Ansprechpartner hinzufügen -->
        
        <v-checkbox class="mt-12"  hide-details="auto" v-bind:label="'Ich erkläre mich damit einverstnden, dass meine Daten zur Bearbeitung meines Anliegens verwendet werden und stimme hiermit den AGB und Datenschutzerklärung zu.'"
                  v-model="configuration.acceptsDataPrivacy"
                  v-bind:rules="[ rules.true ]" />

        <v-alert type="error" class="mt-6" v-bind:value="error">Bitte füllen Sie alle Felder aus.</v-alert>

        <v-btn class="mt-6"
              depressed
              block
              color="primary"
              v-on:click="send">
          <v-icon left>
            mdi-check
          </v-icon>
          Angebot unverbindlich anfordern
        </v-btn>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import rules from '@/mixins/rules'

  import Stepper from '@/components/Stepper.vue'
  import DateField from '@/components/DateField.vue'
  import IconSelectField from '@/components/IconSelectField.vue'
  import CheckboxSelect from '@/components/CheckboxSelect.vue'
  import InvoiceAddress from '@/components/InvoiceAddress.vue'
  import ExtraAddress from '@/components/ExtraAddress.vue'

  export default {
    components: {
      Stepper,
      DateField,
      IconSelectField,
      CheckboxSelect,
      InvoiceAddress,
      ExtraAddress
    },
    mixins: [ rules ],
    computed: {
      ...mapGetters({
        descriptor: 'base/descriptor'
      })
    },
    data: () => ({
      error: false
    }),
    methods: {
      send() {
        this.error = false
        if (!this.$refs.form.validate()) {
          this.error = true
          return
        }

        this.$emit('send')
      }
    },
    name: 'CarpetCleanConfigurationDetails',
    props: {
      configuration: Object
    }
  }
</script>