import Vue from 'vue'

import MailService from '@/api/mail-service'

import settings from '@/settings'

const mailService = new MailService(settings.apiUri)

export default {
  actions: {
    async sendMail(_, { mail }) {
      const accessToken = settings.accessToken

      const result = await mailService.send(mail, { accessToken })

      return result
    }
  },
  getters: {
    descriptor: state => state.descriptor
  },
  mutations: {
  },
  namespaced: true,
  state: {
    descriptor: {
      // Wunschtermin
      date: {
        group: 'Wunschtermin',
        label: 'Wunschtermin 1',
        sort: 1
      },
      date2: {
        group: 'Wunschtermin',
        label: 'Wunschtermin 2',
        sort: 2
      },

      // Objektdetails
      type: {
        type: 'select',
        group: 'Objektdetails',
        label: 'Objektart',
        options: [
          {
            text: 'Wohnung',
            value: 'apartment'
          },
          {
            text: 'Haus',
            value: 'house'
          },
          {
            text: 'Büro',
            value: 'office'
          },
          {
            text: 'Praxis',
            value:'practice'
          },
          {
            text: 'Laden',
            value: 'store'
          },
          {
            text: 'Restaurant',
            value: 'restaurant'
          }
        ],
        sort: 3
      },
      level: {
        group: 'Objektdetails',
        label: 'Stockwerk'
      },
      furniture: {
        type: 'select',
        group: 'Objektdetails',
        label: 'Art der Möblierung',
        options: [
          {
            text: 'möbeliert',
            value: 'moebeliert'
          },
          {
            text: 'teilmöbeliert',
            value: 'teilmoebeliert'
          },
          {
            text: 'unmöbeliert',
            value: 'unmoebeliert'
          }
        ],
        sort: 4
      },

      // Details zur Reinigung
      cleaningTypeRegular: {
        type: 'select',
        group: 'Details zur Reinigung',
        label: 'Anlass der Reinigung (Normale Reinigung)',
        options: [
          {
            text: 'Normale Reinigung',
            value: 'regular-cleaning'
          },
          {
            text: 'Endreinigung',
            value: 'endreinigung'
          },
          {
            text: 'Umzugsreinigung',
            value: 'umzugsreinigung'
          },
          {
            text: 'Nach Handwerker',
            value: 'nach-handwerker'
          },
          {
            text: 'Frühjahreputz',
            value: 'fruejahresputz'
          },
          {
            text: 'Baureinigung',
            value: 'baureinigung'
          },
          {
            text: 'Stark verschmutztes Objekt',
            value: 'stark-verschmutztes-objekt'
          },
          {
            text: 'Nach Wasserschaden',
            value: 'nach-wasserschaden'
          },
          {
            text: 'Messie Wohnung',
            value: 'messie-wohnung'
          },
          {
            text: 'Taubenkot entfernen',
            value: 'taubenkot-entfernen'
          },
          {
            text: 'Brandreinigung',
            value: 'brandreinigung'
          },
          {
            text: 'Holzterrasse reinigen',
            value: 'holzterrasse-reinigen'
          },
          {
            text: 'Gastronomiereinigung',
            value: 'gastronomiereinigung'
          },
          {
            text: 'Nikotingeruch entfernen',
            value: 'nikitiengeruch-entfernen'
          },
          {
            text: 'Entrümpelung & Entsorgung',
            value: 'entruempelung-entsorgung'
          },
          {
            text: 'Desinfektionsreinigung',
            value: 'desinfektionsreinigung'
          }
        ],
        sort: 5
      },
      cleaningTypeWindow: {
        type: 'select',
        group: 'Details zur Reinigung',
        label: 'Anlass der Reinigung (Fensterreinigung)',
        options: [
          {
            text: 'Glasreinigung',
            value: 'glasreinigung'
          },
          {
            text: 'Wintergarten reinigung',
            value: 'winterGartenReinigung'
          }
        ],
        sort: 6
      },
      cleaningTypeSpringClean: {
        type: 'select',
        group: 'Details zur Reinigung',
        label: 'Anlass der Reinigung (Frühjahrsputz)',
        options: [
          {
            text: 'Normale Reinigung',
            value: 'normale-reinigung'
          },
          {
            text: 'Endreinigung',
            value: 'endreinigung'
          },
          {
            text: 'Umzugsreinigung',
            value: 'umzugsreinigung'
          },
          {
            text: 'Nach Handwerker',
            value: 'nach-handwerker'
          },
          {
            text: 'Frühjahrsputz',
            value: 'fruejahrsputz'
          },
          {
            text: 'Baureinigung',
            value: 'baureinigung'
          },
          {
            text: 'Stark verschmutztes Objekt',
            value: 'stark-verschmutztes-objekt'
          },
          {
            text: 'Nach Wasserschaden',
            value: 'nach-wasserschaden'
          },
          {
            text: 'Messie Wohnung',
            value: 'messie-wohnung'
          },
          {
            text: 'Taubenkot entfernen',
            value: 'taubenkot-entfernen'
          },
          {
            text: 'Brandreinigung',
            value: 'brandreinigung'
          },
          {
            text: 'Holzterrasse reinigen',
            value: 'holzterrasse-reinigen'
          },
          {
            text: 'Gastronomiereinigung',
            value: 'gastronomiereinigung'
          },
          {
            text: 'Nikotingeruch entfernen',
            value: 'nikitiengeruch-entfernen'
          },
          {
            text: 'Entrümpelung & Entsorgung',
            value: 'entruempelung-entsorgung'
          },
          {
            text: 'Desinfektionsreinigung',
            value: 'desinfektionsreinigung'
          }
        ],
        sort: 7
      },
      cleaningTypeFinalClean: {
        type: 'select',
        group: 'Details zur Reinigung',
        label: 'Anlass der Reinigung (Endreinigung)',
        options: [
          {
            text: 'Normale Reinigung',
            value: 'normale-reinigung'
          },
          {
            text: 'Endreinigung',
            value: 'endreinigung'
          },
          {
            text: 'Umzugsreinigung',
            value: 'umzugsreinigung'
          },
          {
            text: 'Nach Handwerker',
            value: 'nach-handwerker'
          },
          {
            text: 'Frühjahreputz',
            value: 'fruejahresputz'
          },
          {
            text: 'Baureinigung',
            value: 'baureinigung'
          },
          {
            text: 'Stark verschmutztes Objekt',
            value: 'stark-verschmutztes-objekt'
          },
          {
            text: 'Nach Wasserschaden',
            value: 'nach-wasserschaden'
          },
          {
            text: 'Messie Wohnung',
            value: 'messie-wohnung'
          },
          {
            text: 'Taubenkot entfernen',
            value: 'taubenkot-entfernen'
          },
          {
            text: 'Brandreinigung',
            value: 'brandreinigung'
          },
          {
            text: 'Holzterrasse reinigen',
            value: 'holzterrasse-reinigen'
          },
          {
            text: 'Gastronomiereinigung',
            value: 'gastronomiereinigung'
          },
          {
            text: 'Nikotingeruch entfernen',
            value: 'nikitiengeruch-entfernen'
          },
          {
            text: 'Entrümpelung & Entsorgung',
            value: 'entruempelung-entsorgung'
          },
          {
            text: 'Desinfektionsreinigung',
            value: 'desinfektionsreinigung'
          }
        ],
        sort: 8
      },
      cleaningTypeMessi: {
        type: 'select',
        group: 'Details zur Reinigung',
        label: 'Anlass der Reinigung (Messi Wohnung)',
        options: [
          {
              text: 'Normale Reinigung',
              value: 'normale-reinigung'
            },
            {
              text: 'Endreinigung',
              value: 'endreinigung'
            },
            {
              text: 'Umzugsreinigung',
              value: 'umzugsreinigung'
            },
            {
              text: 'Nach Handwerker',
              value: 'nach-handwerker'
            },
            {
              text: 'Frühjahreputz',
              value: 'fruejahresputz'
            },
            {
              text: 'Baureinigung',
              value: 'baureinigung'
            },
            {
              text: 'Stark verschmutztes Objekt',
              value: 'stark-verschmutztes-objekt'
            },
            {
              text: 'Nach Wasserschaden',
              value: 'nach-wasserschaden'
            },
            {
              text: 'Messie Wohnung',
              value: 'messie-wohnung'
            },
            {
              text: 'Taubenkot entfernen',
              value: 'taubenkot-entfernen'
            },
            {
              text: 'Brandreinigung',
              value: 'brandreinigung'
            },
            {
              text: 'Holzterrasse reinigen',
              value: 'holzterrasse-reinigen'
            },
            {
              text: 'Gastronomiereinigung',
              value: 'gastronomiereinigung'
            },
            {
              text: 'Nikotingeruch entfernen',
              value: 'nikitiengeruch-entfernen'
            },
            {
              text: 'Entrümpelung & Entsorgung',
              value: 'entruempelung-entsorgung'
            },
            {
              text: 'Desinfektionsreinigung',
              value: 'desinfektionsreinigung'
            }
          ],
          sort: 9
      },
      areaNumber: {
        group: 'Details zur Reinigung',
        label: 'Fläche',
        sort: 10
      },
      dirtyness: {
        type: 'select',
        group: 'Details zur Reinigung',
        label: 'Grad der Verschmutzung',
        options: [
          {
            text: 'Leicht',
            value: 'light',
            icon: 'mdi-numeric-1-circle'
          },
          {
            text: 'Mittel',
            value: 'medium',
            icon: 'mdi-numeric-2-circle'
          },
          {
            text: 'Stark',
            value: 'heavy',
            icon: 'mdi-numeric-3-circle'
          },
          {
            text: 'Extrem',
            value: 'extreme',
            icon: 'mdi-numeric-4-circle'
          }
        ],
        sort: 11
      },
      windowNumber: {
        group: 'Details zur Reinigung',
        label: 'Fensterflügel',
        sort: 12
      },
      maxRoom: {
        group: 'Details zur Reinigung',
        label: 'Max. Raumhöhe',
        sort: 13
      },
      carpetNumber: {
        group: 'Details zur Reinigung',
        label: 'Loser Teppic',
        sort: 14
      },
      totalArea: {
        group: 'Details zur Reinigung',
        label: 'Gesamtfläche',
        sort: 15
      },
      fixedCarpet: {
        group: 'Details zur Reinigung',
        label: 'Fester Teppich',
        sort: 16
      },

      // Reinigungswünsche
      regularServices: {
        type: 'multiple-select',
        group: 'Reinigungswünsche',
        label: 'Gewünschte Reinigungsleistungen (Normale Reinigung)',
        options: [
          {
            text: 'Bad/Sanitär',
            value: 'bathroom'
          },
          {
            text: 'Küche',
            value: 'kitchen'
          },
          {
            text: 'Backofen',
            value: 'oven'
          },
          {
            text: 'Dunstabzug',
            value: 'extractor'
          },
          {
            text: 'Heizung',
            value: 'heating'
          },
          {
            text: 'Türen',
            value: 'doors'
          },
          {
            text: 'Kühlschrank',
            value: 'fridge'
          },
          {
            text: 'Böden reinigen',
            value: 'floor'
          },
          {
            text: 'Balkon',
            value: 'balcony'
          },
          {
            text: 'Möbel/Flächen',
            value: 'furniture'
          },
          {
            text: 'Teppich saugen',
            value: 'carpet'
          },
          {
            text: 'Keller',
            value: 'basement'
          }
        ],
        sort: 17
      },
      windowServices: {
        type: 'multiple-select',
        group: 'Reinigungswünsche',
        label: 'Gewünschte Reinigungsleistungen (Fensterreinigung)',
        options: [
          {
            text: 'innen/außen',
            value: 'insideOutside'
          },
          {
            text: 'nur innen',
            value: 'inside'
          },
          {
            text: 'nur außen',
            value: 'outside'
          },
          {
            text: 'mit Rahmen',
            value: 'withFrame'
          },
          {
            text: 'Fugen/Falze',
            value: 'joints'
          },
          {
            text: 'Fensterbänke',
            value: 'windowSills'
          },
          {
            text: 'Sprossenfenster',
            value: 'sashWindow'
          },
          {
            text: 'Altbaufenster',
            value: 'oldBuildingWindow'
          },
          {
            text: 'Oberlichter',
            value: 'skylights'
          },
          {
            text: 'Wintergarten',
            value: 'winterGarden'
          },
          {
            text: 'Jalousien',
            value: 'blinds'
          },
          {
            text: 'Rolladen',
            value: 'rollerShutters'
          },
          {
            text: 'Vordach',
            value: 'canopy'
          }
        ],
        sort: 18
      },
      carpetServices: {
        type: 'multiple-select',
        group: 'Reinigungswünsche',
        label: 'Gewünschte Reinigungsleistungen (Teppichreinigung)',
        options: [
          {
            text: 'Flecken',
            value: 'stains'
          },
          {
            text: 'Rotwein',
            value: 'redWine'
          },
          {
            text: 'Laufspuren',
            value: 'footprints'
          },
          {
            text: 'Blut',
            value: 'blood'
          },
          {
            text: 'Urin',
            value: 'urine'
          },
          {
            text: 'Erbrochenes',
            value: 'vomit'
          },
          {
            text: 'Umzug',
            value: 'move'
          },
          {
            text: 'Bauarbeiten',
            value: 'construction'
          },
          {
            text: 'Wasserschaden',
            value: 'waterDamage'
          }
        ],
        sort: 19
      },
      springCleanServices: {
        type: 'multiple-select',
        group: 'Reinigungswünsche',
        label: 'Gewünschte Reinigungsleistungen (Frühjahrsputz)',
        options: [
          {
            text: 'Bad/Sanitär',
            value: 'bathroom'
          },
          {
            text: 'Dunstabzug',
            value: 'exhaust'
          },
          {
            text: 'Geschirr',
            value: 'dishes'
          },
          {
            text: 'Dusche',
            value: 'shower'
          },
          {
            text: 'Kochfeld',
            value: 'stove'
          },
          {
            text: 'Küchenmaschinen',
            value: 'kitchenMachines'
          },
          {
            text: 'WC Bereich',
            value: 'toilet'
          },
          {
            text: 'Schubladen',
            value: 'drawers'
          },
          {
            text: 'Spülbereich',
            value: 'sink'
          },
          {
            text: 'Backofen',
            value: 'oven'
          },
          {
            text: 'Möbel/Flächen',
            value: 'furniture'
          },
          {
            text: 'Tische/Stühle',
            value: 'tables'
          },
          {
            text: 'Kühlschrank',
            value: 'fridge'
          },
          {
            text: 'Schrank innen',
            value: 'cabinetInside'
          },
          {
            text: 'Bett/Gestell',
            value: 'bed'
          },
          {
            text: 'Mikrowelle',
            value: 'microwave'
          },
          {
            text: 'Bilder/Rahmen',
            value: 'pictures'
          },
          {
            text: 'Lampen',
            value: 'lamps'
          },
          {
            text: 'Staubwischen',
            value: 'dust'
          },
          {
            text: 'Nachttisch',
            value: 'nightstand'
          },
          {
            text: 'TV/HiFi',
            value: 'tv'
          },
          {
            text: 'Fensterbänke',
            value: 'windowSills'
          },
          {
            text: 'Steckdosen',
            value: 'sockets'
          },
          {
            text: 'Heizung',
            value: 'heating'
          },
          {
            text: 'Lichtschalter',
            value: 'lightSwitches'
          },
          {
            text: 'Böden reinigen',
            value: 'floor'
          },
          {
            text: 'Sockelleisten',
            value: 'baseboards'
          },
          {
            text: 'Spinnweben',
            value: 'cobwebs'
          },
          {
            text: 'Couch saugen',
            value: 'couch'
          },
          {
            text: 'Türen',
            value: 'doors'
          },
          {
            text: 'Teppich saugen',
            value: 'carpet'
          },
          {
            text: 'Balkon',
            value: 'balcony'
          },
          {
            text: 'Keller',
            value: 'basement'
          },
          {
            text: 'Lüftung',
            value: 'ventilation'
          }
        ],
        sort: 20
      },
      finalCleanServices: {
        type: 'multiple-select',
        group: 'Details zur Reinigung',
        label: 'Gewünschte Reinigungsleistungen (Endreinigung)',
        options: [
          {
            text: 'Besenrein',
            value: 'broomClean'
          },
          {
            text: 'Schrank innen',
            value: 'cabinetInside'
          },
          {
            text: 'Türen',
            value: 'doors'
          },
          {
            text: 'Balkon',
            value: 'balcony'
          },
          {
            text: 'Objektübergabe',
            value: 'objectHandover'
          },
          {
            text: 'Mieterwechsel',
            value: 'tenantChange'
          },
          {
            text: 'Bad/Sanitär',
            value: 'bathroom'
          },
          {
            text: 'Küche',
            value: 'kitchen'
          },
          {
            text: 'Böden reinigen',
            value: 'cleanFloors'
          },
          {
            text: 'Keller',
            value: 'basement'
          }
        ],
        sort: 21
      },
      cushionServices: {
        type: 'multiple-select',
        group: 'Reinigungswünsche',
        label: 'Gewünschte Reinigungsleistungen (Polsterreinigung)',
        options: [
          {
            text: 'Flecken',
            value: 'stains'
          },
          {
            text: 'Rotwein',
            value: 'redWine'
          },
          {
            text: 'Milch',
            value: 'milk'
          },
          {
            text: 'Blut',
            value: 'blood'
          },
          {
            text: 'Urin',
            value: 'urine'
          },
          {
            text: 'Erbrochenes',
            value: 'vomit'
          },
          {
            text: 'Stiftspuren',
            value: 'penMarks'
          },
          {
            text: 'Bauarbeiten',
            value: 'construction'
          },
          {
            text: 'Wasserschaden',
            value: 'waterDamage'
          }
        ],
        sort: 22
      },
      meessiServices: {
        type: 'multiple-select',
        group: 'Reinigungswünsche',
        label: 'Gewünschte Reinigungsleistungen (Messi Wohnung)',
        options: [
          {
            text: 'Entrümpelung',
            value: 'clearingOut'
          },
          {
            text: 'Entsorgung',
            value: 'disposal'
          },
          {
            text: 'Desinfektion',
            value: 'disinfection'
          },
          {
            text: 'Bad/Sanitär',
            value: 'bathroom'
          },
          {
            text: 'Küche',
            value: 'kitchen'
          },
          {
            text: 'Möbel/Flächen',
            value: 'furniture'
          },
          {
            text: 'Balkon',
            value: 'balcony'
          },
          {
            text: 'Keller',
            value: 'cellar'
          },
          {
            text: 'Fäkalien',
            value: 'faeces'
          }
        ],
        sort: 23
      },

      // Sitzmöbel
      twoSeater: {
        group: 'Sitzmöbel',
        label: '2-Sitzer',
        sort: 24
      },
      threeSeater: {
        group: 'Sitzmöbel',
        label: '3-Sitzer',
        sort: 25
      },


      cornerCouchSmall: {
        group: 'Sitzmöbel',
        label: 'Eck-Couch klein',
        sort: 25
      },
      cornerCouchBig: {
        group: 'Sitzmöbel',
        label: 'Eck-Couch groß',
        sort: 25
      },
      armchair: {
        group: 'Sitzmöbel',
        label: 'Sessel',
        sort: 25
      },
      hocker: {
        group: 'Sitzmöbel',
        label: 'Hocker',
        sort: 25
      },
      chairWithBack: {
        group: 'Sitzmöbel',
        label: 'Stuhl mit Lehne',
        sort: 25
      },
      chariWithoutBack: {
        group: 'Sitzmöbel',
        label: 'Stuhl ohne Lehne',
        sort: 25
      },
      couch: {
        group: 'Sitzmöbel',
        label: 'Couch individuell',
        sort: 25
      },

      

      // Zusätzliche Informationen
      comment: {
        group: 'Zusätzliche Informationen',
        label: 'Beschreiben Sie Ihre geplante Reinigung',
        sort: 26
      },

      // Rechnungsadresse
      email: {
        group: 'Rechnungsadresse',
        label: 'E-Mail-Adresse',
        sort: 27
      },
      mobilNumber: {
        group: 'Rechnungsadresse',
        label: 'Handynummer',
        sort: 28
      },
      company: {
        group: 'Rechnungsadresse',
        label: 'Firma',
        sort: 29
      },
      salutationType: {
        type: 'select',
        group: 'Rechnungsadresse',
        label: 'Anrede',
        options: [
          {
            text: 'Herr',
            value: 'mr'
          },
          {
            text: 'Frau',
            value: 'mrs'
          }
        ],
        sort: 30
      },
      titleType: {
        type: 'select',
        group: 'Rechnungsadresse',
        label: 'Titel',
        options: [
          {
            text: 'Dr.',
            value: 'dr'
          },
          {
            text: 'Prof.',
            value: 'prof'
          },
          {
            text: 'Prof. Dr.',
            value: 'profDr'
          },
          {
            text: 'Mag.',
            value: 'mag'
          },
          {
            text: 'Dr. Mag.',
            value: 'drMag'
          },
          {
            text: 'Prof. Dr. Mag.',
            value: 'profDrMag'
          }
        ],
        sort: 31
      },
      givenName: {
        group: 'Rechnungsadresse',
        label: 'Vorname',
        sort: 32
      },
      familyName: {
        group: 'Rechnungsadresse',
        label: 'Nachname',
        sort: 33
      },
      street: {
        group: 'Rechnungsadresse',
        label: 'Straße',
        sort: 34
      },
      streetNumber: {
        group: 'Rechnungsadresse',
        label: 'Nr.',
        sort: 35
      },
      postalCode: {
        group: 'Rechnungsadresse',
        label: 'PLZ',
        sort: 36
      },
      city: {
        group: 'Rechnungsadresse',
        label: 'Stadt',
        sort: 37
      },
      land: {
        group: 'Rechnungsadresse',
        label: 'Land',
        sort: 38
      },

      // Seperate Reinigungsadresse
      extraAdress: {
        type: 'yes-no',
        group: 'Seperate Reinigungsadresse',
        label: 'Seperate Reinigungsadresse vorhanden',
        sort: 39
      },
      company2: {
        group: 'Seperate Reinigungsadresse',
        label: 'Firma',
        sort: 40
      },
      street2: {
        group: 'Seperate Reinigungsadresse',
        label: 'Straße',
        sort: 41
      },
      streetNumber2: {
        group: 'Seperate Reinigungsadresse',
        label: 'Nr.',
        sort: 42
      },
      postalCode2: {
        group: 'Seperate Reinigungsadresse',
        label: 'PLZ',
        sort: 43
      },
      city2: {
        group: 'Seperate Reinigungsadresse',
        label: 'Stadt',
        sort: 44
      },
      land2: {
        group: 'Seperate Reinigungsadresse',
        label: 'Land',
        sort: 45
      },

      // Datenschutz
      acceptsDataPrivacy: {
        type: 'yes-no',
        group: 'Datenschutz',
        label: 'Ich erkläre mich damit einverstnden, dass meine Daten zur Bearbeitung meines Anliegens verwendet werden und stimme hiermit den AGB und Datenschutzerklärung zu.',
      }
  
    }

    
  }
}