<template>
  <div>
    <div class="header grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="12"
                 lg="6">
            <template v-if="!emailSent">
                
              <service-configuration-card v-bind:configuration="configuration"
                                        v-bind:tab.sync="tab"
                                        v-on:send="send" />
            </template>
            <template v-else>
              <v-card flat>
                <v-card-text>
                  <h4 class="text-h4">Anfrage gesendet</h4>
                  <p>Ihre Anfrage wurde erfolgreich übermittelt.</p>
                </v-card-text>
              </v-card>
            </template>


          </v-col>
          <v-col cols="12" 
                 lg="6">
            <image-slider v-bind:images="images" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import ServiceConfigurationCard from '@/components/ServiceConfigurationCard.vue'
  import ImageSlider from '@/components/ImageSlider.vue'

  export default {
    computed: {
      images() {
        return [
        {
          id: 'slide1',
          src: '/images/slide1.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung für ${this.city} für Haus, Wohnung und im Büro`
        },
        {
          id: 'slide2',
          src: '/images/slide2.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung für ${this.city} für Haus, Wohnung und im Büro`
        },
        {
          id: 'slide3',
          src: '/images/slide3.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung für ${this.city} für Haus, Wohnung und im Büro`
        },
        {
          id: 'slide4',
          src: '/images/slide4.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung in ${this.city} für Haus, Wohnung und im Büro`
        },
        {
          id: 'slide5',
          src: '/images/slide5.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung in ${this.city} für Haus, Wohnung und im Büro`
        },
        {
          id: 'slide6',
          src: '/images/slide6.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung für ${this.city} für Haus, Wohnung und im Büro`
        },
        {
          id: 'slide7',
          src: '/images/slide7.jpg',
          title: 'Sichern Sie sich jetzt Ihren Wunschtermin',
          subtitle: `Professionelle Reinigung für ${this.city} für Haus, Wohnung und im Büro`
        }
      ]
      },
      emailText() {
        const getValue = key => {
          const descriptor = this.descriptor[key]

          if (descriptor?.type === 'select') {
            const option = descriptor.options.find(option => option.value === this.configuration[key])

            return option?.text ?? this.configuration[key]
          }

          if (descriptor?.type === 'multiple-select') {
            const options = descriptor.options.filter(option => this.configuration[key].includes(option.value))

            return options.map(option => option.text).join(', ')
          }

          if (descriptor?.type === 'yes-no') {
            return this.configuration[key] ? 'Ja' : 'Nein'
          }

          return this.configuration[key] ?? ''
        }

        // get all the keys of the configuration object
        const keys = Object.keys(this.configuration)
        
        const values = keys.map(key => ({
          key,
          value: getValue(key),
          label: this.descriptor[key]?.label ?? key,
          group: this.descriptor[key]?.group ?? 'Unbekannt',
          sort: this.descriptor[key]?.sort ?? 9999
        }))

        // split values into groups
        const groups = values.reduce((groups, value) => {
          const group = value.group
          if (!groups[group]) {
            groups[group] = []
          }
          groups[group].push(value)
          return groups
        }, {})

        return Object.keys(groups).map(group => `<h2>${group}</h2><ul>${groups[group].sort((a, b) => a.sort - b.sort).map(value => `<li><strong>${value.label}</strong>: ${value.value}</li>`).join('')}</ul>`).join('')
      },
      ...mapGetters({
        descriptor: 'base/descriptor'
      })
    },
    components: {
      ServiceConfigurationCard,
      ImageSlider
    },
    data: () => ({
      city: 'München',
      emailSent: false,
      configuration: {
        land: 'Deutschland',
        land2: 'Deutschland',
        cleaningTypeRegular: 'regular-cleaning',
        cleaningTypeWindow: 'glasreinigung',
        cleaningTypeSpringClean: 'fruejahrsputz',
        cleaningTypeMessi: 'messie-wohnung',
        regularServices: [],
        windowServices: [],
        carpetServices: [],
        cushionServices: [],
        springCleanServices: [],
        finalCleanServices: [],
        meessiServices: [],
        threeSeater: 0,
        twoSeater: 0,
        cornerCouchSmall: 0,
        cornerCouchBig: 0,
        armchair: 0,
        hocker: 0,
        chairWithBack: 0,
        chariWithoutBack: 0,
        couch: 0
      },
      tab: null
    }),
    created() {
      let city = this.$route.query?.city

      if (!!city) {
        city = city.replace(/_/g, ' ')
        this.city = city
      }
    },
    methods: {
      async send(type) {


        const resultEmail1 = await this.sendMail({ mail: {
          email: this.configuration.email,
          subject: 'Vielen Dank für Ihre Anfrage',
          text: `Sehr geehrte/r ${this.configuration.givenName} ${this.configuration.familyName},<br><br>wir hoffen, diese E-Mail erreicht Sie in bester Verfassung.<br><br>Wir möchten uns herzlich für Ihre Anfrage bedanken, die Sie über unser Online-Formular für unseren Reinigungsservice gesendet haben. Es freut uns sehr, dass Sie Interesse an unseren Dienstleistungen haben.<br><br>Ihre Anfrage ist bei uns eingegangen und wird nun von unserem Team sorgfältig überprüft, um sicherzustellen, dass wir Ihnen eine maßgeschneiderte Lösung für Ihre Reinigungsbedürfnisse anbieten können. Wir legen großen Wert darauf, unseren Kunden qualitativ hochwertige Dienstleistungen zu bieten, die ihre Erwartungen übertreffen.<br><br>Einer unserer freundlichen Mitarbeiter wird sich in Kürze mit Ihnen in Verbindung setzen, um weitere Details zu besprechen, offene Fragen zu klären und gegebenenfalls einen Termin für eine Besichtigung zu vereinbaren. Unser Ziel ist es, Ihnen eine individuelle und auf Ihre Bedürfnisse zugeschnittene Reinigungslösung anzubieten.<br><br>Falls Sie in der Zwischenzeit weitere Fragen haben oder weitere Informationen benötigen, zögern Sie bitte nicht, sich mit uns in Verbindung zu setzen. Wir stehen Ihnen gerne zur Verfügung.<br><br>Nochmals vielen Dank, dass Sie sich für unser Reinigungsunternehmen entschieden haben. Wir freuen uns darauf, Ihnen den bestmöglichen Service zu bieten und Ihr Vertrauen zu rechtfertigen.`
        }})

        const resultEmail2 = await this.sendMail({ mail: {
          email: 'info@nimanaj-dienstleistungen.de',
          subject: 'Neue Anfrage',
          text: `Sie haben eine neue Anfrage über das Online-Anfrageformular erhalten: ${this.emailText}`
        }})


        if (resultEmail1 && resultEmail2) {
          this.emailSent = true
        }
      },
      ...mapActions({
        sendMail: 'base/sendMail'
      })
    },
    name: 'Home'
  }
</script>

<style>
  .header {
    background-size: cover;
    background-position: center;
    min-height: 640px;
  }

  .header--image-0 {
    background-image: url('~@/assets/images/pexels-karolina-grabowska-4239037.jpg');
  }

  .header--image-1 {
    background-image: url('~@/assets/images/pexels-nathan-cowley-713297.jpg');
  }

  .header--image-2 {
    background-image: url('~@/assets/images/pexels-karolina-grabowska-4239037.jpg');
  }

  .header--image-3 {
    background-image: url('~@/assets/images/pexels-nathan-cowley-713297.jpg');
  }

  .header--image-4 {
    background-image: url('~@/assets/images/pexels-karolina-grabowska-4239037.jpg');
  }

  .header--image-5 {
    background-image: url('~@/assets/images/pexels-nathan-cowley-713297.jpg');
  }

  .header--image-6 {
    background-image: url('~@/assets/images/pexels-karolina-grabowska-4239037.jpg');
  }
</style>