export default class MailService {
  constructor (baseUri) {
    this.baseUri = baseUri
  }

  async send(mail, options) {
    const response = await fetch(`${this.baseUri}/mail/send.php`, {
      body: JSON.stringify(mail),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}