import Vue from 'vue'

import App from './App.vue'
import './filters'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.use(VueMask)

new Vue({
  i18n,
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')