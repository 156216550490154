<template>
    <v-carousel v-model="model" height="640" hide-delimiters interval="4000">
      <v-carousel-item v-bind:key="image.id"
                       v-for="image in images">
        <v-img v-bind:src="image.src" contain position="center center" width="100%" height="100%"
               class="align-end">
          <div class="ma-12">
            <p class="overlay text-h5 white--text mb-2 pa-2">{{ image.title }}</p>
            <p class="overlay text-h7 primary--text mb-0 pa-2">{{ image.subtitle }}</p>
          </div>
        </v-img>
      </v-carousel-item>
    </v-carousel>
</template>

<script>
  export default {
    data: () => ({
      model: 0
    }),
    name: 'ImageSlider',
    props: {
      images: Array
    }
  }
</script>

<style scoped>
p.overlay {
  background-color: rgba(0, 0, 0, 0.67);
  margin: 0 auto;
  width: fit-content;
}

</style>