import Home from '@/views/HomeView.vue'
import Primary from '@/views/PrimaryView.vue'

export default [
  {
    components: {
      default: Home
    },
    name: 'home',
    path: '/'
  },
  {
    components: {
      default: Primary
    },
    name: 'primary',
    path: '/primary'
  }
]