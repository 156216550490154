<template>
  <div>
    <v-text-field     v-bind:label="label"
                        filled
                        hide-details="auto"
                        v-bind:value.number="value"
                        type="number"
                        append-icon="mdi-plus"
                        prepend-inner-icon="mdi-minus"
                        v-on:input="$emit('input', $event)"
                        v-on:click:append="$emit('input', value + 1)"
                        v-on:click:prepend-inner="$emit('input', value > 0 ? value - 1 : 0)">
      </v-text-field>
  </div>
</template>

<script>
  export default {
    name: 'Stepper',
    props: {
      value: Number,
      label: String
    }
  }
</script>