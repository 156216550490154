<template>
  <div>
    <p class="text-h5 mt-6">Rechnungsadresse</p>
    <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:rules="[rules.required, rules.email]"
                        v-bind:label="'E-Mail-Adresse'"
                        v-model="configuration.email"
                        />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Handynummer'"
                        v-model="configuration.mobilNumber" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Firma'"
                        v-model="configuration.company" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-select filled
                    hide-details="auto"
                    v-bind:label="'Anrede'"
                    v-bind:items="descriptor.salutationType.options"
                    v-model="configuration.salutationType"
                    v-bind:rules="[ rules.required ]"  />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-select filled
                    hide-details="auto"
                    v-bind:label="'Titel'"
                    v-bind:items="descriptor.titleType.options"
                    v-model="configuration.titleType" />
        </v-col>
      </v-row>      
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Vorname'"
                        v-model="configuration.givenName"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Nachname'"
                        v-model="configuration.familyName"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Straße'"
                        v-model="configuration.street"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Nr.'"
                        v-model="configuration.streetNumber"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'PLZ'"
                        v-model="configuration.postalCode"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="'Stadt'"
                        v-model="configuration.city"
                        v-bind:rules="[ rules.required ]"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field disabled
                        filled
                        hide-details="auto"
                        v-bind:label="'Land'"
                        v-model="configuration.land" />
        </v-col>
      </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import rules from '@/mixins/rules'

  export default {
    computed: {
      ...mapGetters({
        descriptor: 'base/descriptor'
      })
    },
    mixins: [ rules ],
    name: 'InvoiceAdress',
    props: {
      configuration: Object
    }
  }
</script>