<template>
  <div>
    <v-row>
      <v-col cols="12"
             md="12"
             v-for="item in items"
             v-bind:key="item.label" >
        <v-checkbox v-bind:label="item.label"
                    v-bind:value="item.label" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: ()=> ({
    }),
    name: 'CheckboxDsgvo',
    props: {
      items: Array
    }
  }
</script>